<template>
  <div v-if="data">
    <div>
      <p>Medical Summary:</p>
      <div>
        <span>The patient reported of a work related injury.</span>
        <div
          v-if="totalCurrentSymptom && totalCurrentSymptom.pain_frequency"
          class="d-inline"
        >
          <p class="d-inline">
            <span class="text-capitalize">{{ gender }}</span>
            reported of ongoing symptoms such as:
          </p>
          <p class="d-inline">
            The patient reported {{ totalCurrentSymptom.pain_frequency }} ,
            {{ 'pain of ' + totalCurrentSymptom.min_pain_score }} -
            {{ totalCurrentSymptom.max_pain_score }} at
            {{ totalCurrentSymptom.location }},
            <span v-if="totalCurrentSymptom.majority_pain_score">
              and most of the time
              {{ totalCurrentSymptom.majority_pain_score }} out of 10 in
              severity.
            </span>
          </p>
        </div>
        <span
          v-if="
            totalCurrentSymptom &&
            totalCurrentSymptom.aggravating_factors.length > 0
          "
        >
          Pain was worsened with
          {{ formatLongConjunction(totalCurrentSymptom.aggravating_factors) }}.
        </span>

        <span v-else>
          The patient did not report of any aggravating factor or loss of
          activities of living.
        </span>

        At the time of this physical examination,
        {{ gender }} {{ musclesText }} The patient had
        {{ movementImpairmentText }}. The patient had
        {{ radiologicalSelection }}. {{ specificDiagnosis }}.
      </div>

      <div class="my-4">
        <span>
          The reported ADLs loss is
          {{ data.diagnosis.adl_consistent ? 'consistent' : 'not consistent' }}
          with the clinical assessment.
        </span>
      </div>
    </div>

    <!-- SECOND STAGE  -->
    <p>Impairment Calculations:</p>
    <div v-if="dreLevel.category === 1" id="DRELEVEL1">
      <p>
        As per the AMA5 and the Return To Work Scheme Impairment Assessment
        Guidelines, the method for assessment is the DRE: {{ selectedPart }} of
        Section 15.4, Page 384 of AMA 5. There was a history of injury. The
        initial symptoms completely resolved. The patient did not have any
        evidence of muscular spasm or asymmetrical loss of range of movement on
        the clinical examination at this assessment.
      </p>
      <p id="final_paragraph_of_DRE_one">
        The condition qualified for DRE Category I of Table 15-3 of AMA 5, page
        384, and has 0% whole person impairment.
      </p>
    </div>

    <div v-if="dreLevel.category === 2" id="DRELEVEL2">
      <p>
        As per the AMA5 and the Return To Work Scheme Impairment Assessment
        Guidelines, the method for assessment is the DRE: {{ selectedPart }} of
        Section 15.4, Page 384 of AMA 5. There was a reported history of injury,
        <span
          v-if="totalCurrentSymptom && totalCurrentSymptom.pain_frequency"
          class="d-inline"
        >
          <p class="d-inline">
            the patient reported of ongoing symptoms such as:
          </p>
          <p class="d-inline">
            The patient reported {{ totalCurrentSymptom.pain_frequency }} ,
            {{ 'pain of ' + totalCurrentSymptom.min_pain_score }} -
            {{ totalCurrentSymptom.max_pain_score }} at
            {{ totalCurrentSymptom.location }},
            <span v-if="totalCurrentSymptom.majority_pain_score">
              and most of the time
              {{ totalCurrentSymptom.majority_pain_score }} out of 10 in
              severity.
            </span>

            <span v-if="totalCurrentSymptom.aggravating_factors.length > 0">
              Pain was worsened with
              {{
                formatLongConjunction(totalCurrentSymptom.aggravating_factors)
              }}.
            </span>
          </p>
        </span>

        <span v-else>had no reported ongoing symptom,</span>

        reported examination findings where the patient {{ musclesText }} , and
        {{ movementImpairmentText }}.
      </p>

      <RadiculogySection
        :is-radiculopathy-present="isRadiculopathyPresent"
        :data="data.radiculopathy"
        :abnormal-findings="data.findings"
        :spine="data.spine_name"
      />

      <p id="final_paragraph_of_DRE_two">
        The condition qualified for DRE Category II of Table 15-3 of AMA 5, page
        384, and has 5% whole person impairment
        <span v-if="isRadiculopathyPresent === 'non-verifiable'">
          because the patient has non-verifiable radicular complaints
        </span>
        .
      </p>
    </div>

    <div v-if="dreLevel.category === 3" id="DRELEVEL3">
      <RadiculogySection
        :is-radiculopathy-present="isRadiculopathyPresent"
        :data="data.radiculopathy"
        :abnormal-findings="data.findings"
        :spine="data.spine_name"
      />

      <!-- fracture -->
      <div v-if="sumOfSpinalFracture >= 25 && sumOfSpinalFracture < 51">
        <!-- fracture text output: “from the Vertebral Height Loss (%) free text” and “Spine level”, repeat if more than 1 -->
        <p class="text-decoration-underline">Spinal Fracture</p>
        <VertebralHeightLossParagraph
          :data="data.findings.abnormal_radiological_findings"
          :sum="sumOfSpinalFracture"
          :spine="selectedPart"
        />
      </div>

      <span
        v-if="
          data.findings.abnormal_radiological_findings.spinal_fractures &&
          data.findings.abnormal_radiological_findings.spinal_fractures
            .fracture_posterior_element === 'multiple'
        "
      >
        <span>
          The patient had multiple {{ selectedPart }} posterior element
          fractures on the radiological test. According to the Paragraph 4.22,
          Page 43, of Return To Work Scheme Impairment Assessment Guidelines,
          <span class="italic">
            “Posterior element (i.e. lamina, pars and pedicle) fractures at a
            single level are assessed as DRE II and at multiple levels are
            assessed as DRE III.”
          </span>
        </span>
      </span>

      <p v-if="data.diagnosis.surgical_decompression">
        The patient had {{ selectedPart }} decompression surgery. According to
        the Paragraph 4.28, page 45, of the Return To Work Scheme Impairment
        Assessment Guidelines “surgical decompression for spinal stenosis is DRE
        III”. One of the criteria for the DRE Lumbar Category III of Table 15-3,
        AMA5 Guides, is “individuals who had surgery for radiculopathy that are
        now asymptomatic”.
      </p>

      <span id="final_paragraph_of_DRE_three">
        According to this paragraph, the patient qualified for the DRE
        {{ selectedPart }}
        category III of Table 15-3, Page 384 of AMA5 Guides. The DRE
        {{ selectedPart }} category III of Table 15-3, Page 384 of AMA5 Guides
        is 10% WPI.
      </span>
    </div>

    <div v-if="dreLevel.category === 4" id="DRELEVEL4">
      <!-- fracture -->

      <!-- fracture text output: “from the Vertebral Height Loss (%) free text” and “Spine level”, repeat if more than 1 -->
      <p
        v-if="
          data.findings.abnormal_radiological_findings.spinal_fractures &&
          sumOfSpinalFracture > 50
        "
      >
        <VertebralHeightLossParagraph
          :data="data.findings.abnormal_radiological_findings"
          :sum="sumOfSpinalFracture"
          :spine="selectedPart"
        />

        <span>
          This qualified for the “Total loss of more than 50%= DRE IV” in the
          Paragraph 4.22, page 43, of the Return To Work Scheme Impairment
          Assessment Guidelines and the “without residual neurologic compromise”
          of DRE IV, page 384, AMA 5 Guides.
        </span>
      </p>

      <div
        v-if="
          data.findings.abnormal_radiological_findings.spinal_fractures &&
          data.findings.abnormal_radiological_findings.spinal_fractures
            .fracture_alteration
        "
        class="d-inline"
      >
        <p class="d-inline">
          The patient had confirmed loss of motion segment integrity (or
          fusion/arthrodesis) on radiological study.
        </p>
      </div>

      <p id="final_paragraph_of_DRE_four">
        This qualified for the requirements of the DRE
        {{ selectedPart }} category IV of Table 15-3, Page 384, AMA5 Guides.
      </p>
    </div>

    <div v-if="dreLevel.category === 5" id="DRELEVEL5">
      <RadiculogySection
        :is-radiculopathy-present="isRadiculopathyPresent"
        :data="data.radiculopathy"
        :abnormal-findings="data.findings"
        :spine="data.spine_name"
      />
      <!-- fracture -->
      <p
        v-if="
          data.findings.abnormal_radiological_findings.spinal_fractures &&
          sumOfSpinalFracture > 50
        "
      >
        <VertebralHeightLossParagraph
          :data="data.findings.abnormal_radiological_findings"
          :sum="sumOfSpinalFracture"
          :spine="selectedPart"
        />
      </p>
      <span
        v-if="
          data.findings.abnormal_radiological_findings.spinal_fractures &&
          data.findings.abnormal_radiological_findings.spinal_fractures
            .fracture_posterior_element === 'multiple'
        "
      >
        <span>
          The patient had multiple {{ selectedPart }} posterior element
          fractures on the radiological test. According to the Paragraph 4.22,
          Page 43, of Return To Work Scheme Impairment Assessment Guidelines,
          <span class="italic">
            “Posterior element (i.e. lamina, pars and pedicle) fractures at a
            single level are assessed as DRE II and at multiple levels are
            assessed as DRE III.”
          </span>
        </span>
      </span>

      <p v-if="data.diagnosis.surgical_decompression">
        The patient had {{ selectedPart }} decompression surgery. According to
        the Paragraph 4.28, page 45, of the Return To Work Scheme Impairment
        Assessment Guidelines “surgical decompression for spinal stenosis is DRE
        III”. One of the criteria for the DRE Lumbar Category III of Table 15-3,
        AMA5 Guides, is “individuals who had surgery for radiculopathy that are
        now asymptomatic”.
      </p>
      <div
        v-if="
          data.findings.abnormal_radiological_findings.spinal_fractures &&
          data.findings.abnormal_radiological_findings.spinal_fractures
            .fracture_alteration
        "
        class="d-inline"
      >
        <p class="d-inline">
          The patient had confirmed loss of motion segment integrity (or
          fusion/arthrodesis) on radiological study with clinical features.
        </p>
      </div>

      <p id="final_paragraph_of_DRE_five" class="my-3">
        Overall the patient’s {{ selectedPart }} condition met the requirements
        for the DRE Lumbar Category V, Table 15-3, Page 384 the AMA5 Guides and
        had 25% WPI.
      </p>
    </div>

    <!-- to have impairment table in future? -->

    <!-- THIRD STAGE -->
    <div class="my-3">
      <!-- ADL output based on nurse tab inputs -->
      <div v-if="totalCurrentSymptom" class="d-inline">
        <div class="d-inline">
          <span>
            The patient also reported of ability to
            {{
              totalCurrentSymptom.adl_loss_lifting
                ? totalCurrentSymptom.adl_loss_lifting
                : 'reported no ADLs loss of lifting'
            }},
            {{
              totalCurrentSymptom.adl_loss_walking
                ? totalCurrentSymptom.adl_loss_walking
                : 'reported no ADLs loss of walking'
            }}
            and
            {{
              totalCurrentSymptom.adl_loss_personal_care
                ? totalCurrentSymptom.adl_loss_personal_care
                : 'reported no ADLs loss of personal care'
            }}.
          </span>

          <div
            v-if="
              !data.diagnosis.adl_consistent &&
              data.diagnosis.adl_inconsistent_reasons
            "
            class="d-inline"
          >
            <p class="d-inline">
              The reported ADLs loss is not consistent with the clinical
              assessment because {{ data.diagnosis.adl_inconsistent_reasons }}.
              The Return To Work Scheme Impairment Assessment Guidelines,
              paragraphs 4.24, 4.25, 4.26 and 4.27, Page 4, cannot be applied in
              this case.
            </p>
          </div>

          <div v-else class="d-inline">
            <p v-if="totalCurrentSymptom.adl_input" class="d-inline">
              The reported ADLs loss is consistent with the clinical assessment.
              The Return To Work Scheme Impairment Assessment Guidelines,
              paragraphs 4.24, 4.25, 4.26 and 4.27, Page 4,
            </p>

            <p v-else class="d-inline">
              The patient did not report of any impact on the activities of
              daily living. The Return To Work Scheme Impairment Assessment
              Guidelines, paragraph 4.24, Page 4, allocates 0% WPI.
            </p>

            <div v-if="aggravatingFactorsCategoryIs.self_care" class="d-inline">
              <p class="d-inline">
                allocates 3% WPI adjustment because “the Workers capacity to
                undertake personal care activities such as dressing, washing,
                toileting and shaving have been affected” (Paragraph 4.26 of the
                IAGs).
              </p>
            </div>

            <div
              v-else-if="aggravatingFactorsCategoryIs.home_care"
              class="d-inline"
            >
              <p class="d-inline">
                allocates 2% WPI because “the worker can manage personal care,
                but is restricted with usual household tasks such as cooking,
                vacuuming, making beds or tasks of equal magnitude such as
                shopping, climbing stairs or walking reasonable distances”
                (Paragraph 4.26 of the IAGs).
              </p>
            </div>

            <div
              v-else-if="aggravatingFactorsCategoryIs.recreational"
              class="d-inline"
            >
              <p class="d-inline">
                allocates 1% WPI adjustment because “the patient is able to cope
                with most activities, but unable to get back to previous
                sporting or recreational activities such as gardening, running
                and active hobbies” (Paragraph 4.26 of the IAGs).
              </p>
            </div>
          </div>
        </div>
      </div>

      <p id="final_paragraph_of_third_stage" class="d-inline">
        The ADLs adjustment is added to the above impairment from the Table 15-3
        of the AMA5 Guides as per the paragraph 4.24 of Return To Work Scheme
        Impairment Assessment Guidelines. Therefore there was a total of
        {{ secondAndThridStageAddition }}% whole person impairment.”
      </p>
    </div>

    <!-- FOURTH STAGE -->
    <div v-if="data.diagnosis.surgical_decompression">
      <div v-if="fourthStage.level === 'a'">
        <p>
          The patient had a surgical decompression of the {{ selectedPart }} and
          still had clinical features.
        </p>
        <RadiculogySection
          :is-radiculopathy-present="isRadiculopathyPresent"
          :data="data.radiculopathy"
          :abnormal-findings="data.findings"
          :spine="data.spine_name"
        />

        <p>
          The Paragraph 4.29, Table 4.2, page 45, of the Return To Work Scheme
          Impairment Assessment Guidelines allocates additional 3% WPI modifier
          for “spinal surgery with residual radicular signs and symptoms”. This
          is combined to the {{ secondAndThridStageAddition }} % WPI calculated
          above and the final WPI is {{ fourthStage.wpi }}%.
        </p>
      </div>

      <div v-if="fourthStage.level === 'b'">
        <p>
          The patient had 2 surgical decompressions at different
          {{ selectedPart }} levels and still had clinical features.
        </p>
        <RadiculogySection
          :is-radiculopathy-present="isRadiculopathyPresent"
          :data="data.radiculopathy"
          :abnormal-findings="data.findings"
          :spine="data.spine_name"
        />

        <p>
          The Paragraph 4.29, Table 4.2, page 45, of the Return To Work Scheme
          Impairment Assessment Guidelines allocates additional 3% WPI modifier
          for “spinal surgery with residual radicular signs and symptoms” and 1%
          WPI for “second and third levels operate on”. This table allows 4% WPI
          modifier. This is combined to the {{ secondAndThridStageAddition }} %
          WPI calculated above and the final WPI is {{ fourthStage.wpi }}%.
        </p>
      </div>

      <div v-if="fourthStage.level === 'c'">
        <p>
          The patient had 2 surgical decompressions at different
          {{ selectedPart }} levels and still had clinical features.
        </p>

        <RadiculogySection
          :is-radiculopathy-present="isRadiculopathyPresent"
          :data="data.radiculopathy"
          :abnormal-findings="data.findings"
          :spine="data.spine_name"
        />

        <p>
          The Paragraph 4.29, Table 4.2, page 45, of the Return To Work Scheme
          Impairment Assessment Guidelines allocates additional 3% WPI modifier
          for “spinal surgery with residual radicular signs and symptoms” and 2%
          WPI for “A second operation at the same level”. This table allows 5%
          WPI modifier. This is combined to the
          {{ secondAndThridStageAddition }} % WPI calculated above and the final
          WPI is {{ fourthStage.wpi }}%.
        </p>
      </div>

      <div v-if="fourthStage.level === 'd'">
        <p>
          The patient had {{ data.diagnosis.surgical.num }} surgical
          decompressions of different {{ selectedPart }} levels and still had
          clinical features.
        </p>
        <RadiculogySection
          :is-radiculopathy-present="isRadiculopathyPresent"
          :data="data.radiculopathy"
          :abnormal-findings="data.findings"
          :spine="data.spine_name"
        />

        <p>
          The Paragraph 4.29, Table 4.2, page 45, of the Return To Work Scheme
          Impairment Assessment Guidelines allocates additional 3% WPI modifier
          for “spinal surgery with residual radicular signs and symptoms”, 1%
          WPI for each“ Second and third levels operated-each additional level”
          and 1% for each “third and subsequent operations”. This table allows 3
          + {1x number of surgeries} + {1x number of surgeries-1} % WPI
          modifier.This is combined to the
          {{ secondAndThridStageAddition }} % WPI calculated above and the final
          WPI is {{ fourthStage.wpi }}%.
        </p>
      </div>

      <div v-if="fourthStage.level === 'e'">
        <p>
          The patient had {{ data.diagnosis.surgical.num }} surgical
          decompressions of different {{ selectedPart }} levels and also repeat
          surgery/surgeries on the same level.
        </p>
        <RadiculogySection
          :is-radiculopathy-present="isRadiculopathyPresent"
          :data="data.radiculopathy"
          :abnormal-findings="data.findings"
          :spine="data.spine_name"
        />
        <!-- use the combination formula to combine {the result from the (3 + 2 + {1x number of surgeries-1} + {1x number of surgeries-1} for the final WPI -->
        <p>
          The Paragraph 4.29, Table 4.2, page 45, of the Return To Work Scheme
          Impairment Assessment Guidelines allocates additional 3% WPI modifier
          for “spinal surgery with residual radicular signs and symptoms”, 2%
          WPI for “a second operation at the same level”, 1% WPI for each“
          Second and third levels operated-each additional level” and 1% for
          each “third and subsequent operations”. This table allows (3 + 2 + {1x
          number of surgeries-1} + {1x number of surgeries-1}) % WPI modifier.
          This is combined to the {{ secondAndThridStageAddition }} % WPI
          calculated above and the final WPI is {{ fourthStage.wpi }}%.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import RadiculogySection from '@/components/Report/SpineOutputSections/RadiculogySection.vue'
import VertebralHeightLossParagraph from '@/components/Report/SpineOutputSections/Radiculopathy/VertebralHeightLossParagraph.vue'

import { formatLongConjunction } from '@/utils/generics.js'
import { getCombinedValue } from '@/utils/calculations.js'

export default {
  name: 'SpineReportOutput',
  components: {
    RadiculogySection,
    VertebralHeightLossParagraph,
  },

  props: {
    gender: { type: String, default: 'the patient' },
    data: { type: Object, required: true },
  },

  emits: ['update:wpiOutput'],

  data() {
    return {
      outputStage: 0,
      selectedPart: this.data.spine_name,
      spinalFracture: {},

      //options
      radiculopathyCriterias: {
        major: ['reflexes', 'muscle_weakness', 'sensation'],
        normal: [
          'abnormal_radiological_findings',
          'nerve_root_tension_test',
          'muscle_wasting',
          'roos_test',
        ],
      },
      aggravatingFactorsCategories: {
        recreational: ['lawn mowing', 'gardening', 'running', 'active hobbies'],
        home_care: [
          'mopping',
          'vacuuming',
          'cleaning house',
          'making beds',
          'cleaning dishes',
          'climbing stairs',
          'walking reasonable distances',
        ],
        self_care: [
          'dressing',
          'washing',
          'toileting',
          'shaving',
          'tying shoelaces',
        ],
      },

      //value to sentence
      adls: {
        lifting: [
          {
            key: '-',
            value: null,
          },
          {
            key: 'lift heavy weights without any discomfort',
            value: 'stage_1',
          },
          {
            key: 'lift heavy weights only if they are well positioned',
            value: 'stage_2',
          },
          {
            key: 'lift light to medium weights if they are well positioned (10 kgs)',
            value: 'stage_3',
          },
          {
            key: 'lift light 5 kg objects from above shoulder height',
            value: 'stage_4',
          },
          {
            key: 'only lift very light weights (under 5 kgs)',
            value: 'stage_5',
          },
          { key: 'not lift or carry anything at all', value: 'stage_6' },
        ],

        walking: [
          {
            key: '-',
            value: null,
          },
          {
            key: 'discomfort stops walking of more than 1km',
            value: 'stage_1',
          },
          {
            key: 'discomfort stops walking than 500m',
            value: 'stage_2',
          },
          {
            key: 'discomfort stops walking than 200m',
            value: 'stage_3',
          },
          {
            key: 'able to only walk a limited distance or use crutches/stick',
            value: 'stage_4',
          },
        ],

        personal_care: [
          {
            key: '-',
            value: null,
          },
          {
            key: 'able to take care of themself normally with some extra discomfort',
            value: 'stage_1',
          },
          {
            key: 'is uncomfortable to look after themself and need assistance',
            value: 'stage_2',
          },
          { key: 'reported of weight loss', value: 'stage_3' },
          { key: 'reported of weight gain', value: 'stage_4' },
        ],
      },
    }
  },

  computed: {
    totalCurrentSymptom() {
      let final = {}
      //combine all available current symptoms related to said spine

      if (this.data.symptoms) {
        final = JSON.parse(JSON.stringify(this.data.symptoms))

        //ensure values are lower case
        final.body_part ? (final.body_part = final.body_part.toLowerCase()) : ''
        final.location ? (final.location = final.location.toLowerCase()) : ''

        //convert stage to sentence
        //check if adl is empty

        final.adl_loss_lifting
          ? (final.adl_loss_lifting = this.adls.lifting.find(
              (x) => x.value === this.data.symptoms.adl_loss_lifting
            ).key)
          : ''
        final.adl_loss_walking
          ? (final.adl_loss_walking = this.adls.walking.find(
              (x) => x.value === this.data.symptoms.adl_loss_walking
            ).key)
          : ''
        final.adl_loss_personal_care
          ? (final.adl_loss_personal_care = this.adls.personal_care.find(
              (x) => x.value === this.data.symptoms.adl_loss_personal_care
            ).key)
          : ''

        //if all empty then = no adl sentence
        !final.adl_loss_lifting &&
        !final.adl_loss_walking &&
        !final.adl_loss_personal_care
          ? (final.adl_input = false)
          : (final.adl_input = true)

        //convert value to sentence
        final.pain_frequency === 'C'
          ? (final.pain_frequency = 'constant')
          : final.pain_frequency === 'I'
          ? (final.pain_frequency = 'intermittent')
          : ''
      } else final = null

      return final
    },

    musclesText() {
      let text = ''
      let tenderTextAry = []
      let spasmTextAry = []

      if (this.isNormalMuscles(this.data.muscles)) {
        text = 'had normal muscle tone without any spasm or tenderness.'
      } else {
        this.data.muscles.forEach((m) => {
          let tenderText = ''
          let spasmText = ''

          m.tenderness === 'none'
            ? (tenderText += 'had no tenderness on the ' + m.name)
            : m.tenderness === 'both'
            ? (tenderText += 'had tenderness on the ' + m.name + ' bilaterally')
            : (tenderText +=
                'had tenderness on the ' + m.tenderness + ' ' + m.name)

          m.spasm === 'none'
            ? (spasmText += 'had no spasm on the ' + m.name)
            : m.spasm === 'both'
            ? (spasmText += 'had spasm of the ' + m.name + ' bilaterally')
            : (spasmText += 'had spasm of the ' + m.spasm + ' ' + m.name)

          tenderTextAry.push(tenderText)
          spasmTextAry.push(spasmText)
        })

        text =
          tenderTextAry.join(' and ') + ' and ' + spasmTextAry.join(' and ')
      }

      return text
    },

    movementImpairmentText() {
      let ary = this.data.impairment
      let text = ''

      ary.forEach((item, idx) => {
        //if last element then use 'and'
        if (idx === ary.length - 1) {
          text +=
            'and ' +
            `${item.field_name.toLowerCase()} of` +
            ' ' +
            `${item.examination_value}` +
            '°'
        } else
          text +=
            `${item.field_name.toLowerCase()} of` +
            ' ' +
            `${item.examination_value}` +
            '°' +
            ', '
      })
      return (text += ' measured with a dual digital inclinometer')
    },

    fourthStage() {
      let stage = {
        level: 0,
        wpi: 0,
      }

      let num = this.data.diagnosis.surgical.num

      if (this.isRadiculopathyPresent === 'verifiable') {
        if (num === 1) {
          stage = {
            level: 'a',
            wpi: 3,
          }
        }

        if (num === 2) {
          this.data.diagnosis.surgical.same_level
            ? (stage = {
                level: 'c',
                wpi: 5,
              })
            : (stage = {
                level: 'b',
                wpi: 4,
              })
        }

        if (num >= 3) {
          this.data.diagnosis.surgical.same_level
            ? (stage = {
                level: 'e',
                wpi: 3 + 2 + (1 * num - 1) + (1 * num - 1),
              })
            : (stage = {
                level: 'd',
                wpi: 3 + 1 * num + (1 * num - 1),
              })
        }
      }

      //finally combine wpi with previous stages wpi
      stage.wpi += this.secondAndThridStageAddition
      return stage
    },

    radiologicalSelection() {
      let obj = this.data.radiculopathy.abnormal_radiological_findings

      let text = ''

      //filter out null value
      if (obj) {
        Object.keys(obj).forEach((e, idx) => {
          if (obj[e] !== null) {
            //if last element then use 'and'
            if (Object.keys(obj).length === idx - 1) {
              text += 'and' + `${e.replace(/_+/g, ' ')}`
            } else text += `${e.replace(/_+/g, ' ')}` + ', '
          }

          text += 'on radiology test(s)'
        })
      } else text = 'no radiological test'

      return text
    },

    specificDiagnosis() {
      if (this.data.diagnosis.specific.length) {
        let diagnosis = this.formatLongConjunction(this.data.diagnosis.specific)
        return `Overall the patient had clinical features consistent of ${diagnosis}`
        // Danh wants the empty value to show sentence as of below , 14/2/2023
      } else return 'Overall the patient had clinical features consistent of'
    },

    isRadiculopathyPresent() {
      let obj = this.data.radiculopathy
      let finalAry = []
      let finalResult

      //determine is radiculopathy major criteria is found
      if (this.data.findings.consistent_abnormal_clinical_findings) {
        //validate reflexes
        if (obj.reflexes) {
          Object.keys(obj.reflexes).forEach((key) => {
            if (
              obj.reflexes[key].right !== 'normal' &&
              obj.reflexes[key].left !== 'normal'
            ) {
              !finalAry.includes('reflexes') ? finalAry.push('reflexes') : ''
            } else null
          })
        }

        //validate muscle weakness
        if (obj.muscle_weakness) {
          obj.muscle_weakness.power_level !== 'normal'
            ? finalAry.push('muscle_weakness')
            : ''
        }

        //validate abnormal pain
        obj.sensation.pain_level !== 'normal' ? finalAry.push('sensation') : ''

        //validate nerve root test or roos test

        if (obj.nerve_root_tension_test) {
          Object.keys(obj.nerve_root_tension_test).forEach((key) => {
            if (obj.nerve_root_tension_test[key] !== 'negative') {
              !finalAry.includes('nerve_root_tension_test')
                ? finalAry.push('nerve_root_tension_test')
                : ''
            } else null
          })
        }

        if (obj.roos_test) {
          obj.roos_test.value === 'positive' ? finalAry.push('roos_test') : ''
        }

        //validate muscle wasting
        //no muscle wasting for thoracic
        if (obj.muscle_wasting) {
          if (this.data.spine_name === 'lumbar spine') {
            if (
              obj.muscle_wasting.thigh.right - obj.muscle_wasting.thigh.left >=
                2 ||
              obj.muscle_wasting.calf.right - obj.muscle_wasting.calf.left >= 1
            ) {
              finalAry.push('muscle_wasting')
            }
          } else {
            if (
              obj.muscle_wasting.arm.right - obj.muscle_wasting.arm.left >= 2 ||
              obj.muscle_wasting.forearm.right -
                obj.muscle_wasting.forearm.left >=
                1
            ) {
              finalAry.push('muscle_wasting')
            }
          }
        }

        //validate abnormal radiological
        let radiologyObj = this.data.findings.abnormal_radiological_findings
        let radiologyAry = Object.keys(radiologyObj)

        radiologyAry.forEach(
          (e) => radiologyObj[e] == null && delete radiologyObj[e]
        )

        if (
          radiologyAry.length > 0 &&
          this.data.findings.radiology_findings_consistent
        ) {
          finalAry.push('abnormal_radiological_findings')
        }
      }

      if (finalAry.length >= 2) {
        //must have at least one major criteria

        for (let i = 0; i < this.radiculopathyCriterias.major.length; i++) {
          if (finalAry.includes(this.radiculopathyCriterias.major[i])) {
            finalResult = 'verifiable'
            break
          } else finalResult = 'non-verifiable'
        }
      } else if (Object.keys(obj).length === 0) {
        finalResult = false
      } else {
        finalResult = 'non-verifiable'
      }
      return finalResult
    },

    sumOfSpinalFracture() {
      let obj =
        this.data.findings.abnormal_radiological_findings.spinal_fractures
      let totalSum = 0

      if (obj) {
        obj.segments.forEach((e) => (totalSum += e.value))
      }

      return totalSum
    },

    dreLevel() {
      let DRELEVEL = { category: 0, wpi: 0 }

      if (this.isNormalMuscles(this.data.muscles)) {
        DRELEVEL = { category: 1, wpi: 0 }
      } else if (this.isRadiculopathyPresent !== 'verifiable') {
        if (
          this.sumOfSpinalFracture > 50 ||
          (this.data.findings.abnormal_radiological_findings.spinal_fractures &&
            this.data.findings.abnormal_radiological_findings.spinal_fractures
              .fracture_alteration)
        ) {
          DRELEVEL = { category: 5, wpi: 25 }
        } else DRELEVEL = { category: 2, wpi: 5 }
      } else if (this.isRadiculopathyPresent === 'verifiable') {
        if (
          this.sumOfSpinalFracture > 50 ||
          (this.data.findings.abnormal_radiological_findings.spinal_fractures &&
            this.data.findings.abnormal_radiological_findings.spinal_fractures
              .fracture_alteration)
        ) {
          DRELEVEL = { category: 5, wpi: 25 }
        } else if (
          (this.sumOfSpinalFracture >= 25 && this.sumOfSpinalFracture < 51) ||
          (this.data.findings.abnormal_radiological_findings.spinal_fractures &&
            this.data.findings.abnormal_radiological_findings.spinal_fractures
              .fracture_posterior_element === 'multiple') ||
          this.data.diagnosis.surgical_decompression
        ) {
          DRELEVEL = { category: 3, wpi: 10 }
        }
      }

      return DRELEVEL
    },

    aggravatingFactorsCategoryIs() {
      let answer = { self_care: false, home_care: false, recreational: false }

      if (this.totalCurrentSymptom) {
        //checking for category
        this.totalCurrentSymptom.aggravating_factors.forEach((e) => {
          if (
            this.aggravatingFactorsCategories.self_care.includes(
              e.toLowerCase()
            )
          ) {
            answer.self_care = true
          } else if (
            this.aggravatingFactorsCategories.home_care.includes(
              e.toLowerCase()
            )
          ) {
            answer.home_care = true
          } else if (
            this.aggravatingFactorsCategories.recreational.includes(
              e.toLowerCase()
            )
          ) {
            answer.recreational = true
          }
        })

        //get the finalized of WPI of ADL adjustment requirements
        if (answer.self_care) {
          answer.finalWpi = 3
        } else if (answer.home_care) {
          answer.finalWpi = 2
        } else if (answer.recreational) {
          answer.finalWpi = 1
        } else return answer
      }

      return answer
    },

    secondAndThridStageAddition() {
      let wpi = 0
      //need to ensure computed wpi for ADL is true
      if (this.aggravatingFactorsCategoryIs.finalWpi) {
        wpi = this.dreLevel.wpi + this.aggravatingFactorsCategoryIs.finalWpi
      } else wpi = this.dreLevel.wpi

      return wpi
    },
  },

  mounted() {
    this.finalSpineWpi()
  },

  methods: {
    isNormalMuscles(ary) {
      let result = true

      ary.forEach((m) => {
        m.tenderness === 'none' && m.spasm === 'none'
          ? (result = true)
          : (result = false)
      })

      return result
    },

    formatLongConjunction(ary) {
      return formatLongConjunction(ary)
    },

    mergeAryNoDups(ary1, ary2) {
      return [...new Set([...ary1, ...ary2])]
    },

    finalSpineWpi() {
      //send wpi for summary table of report

      // Temporary forcefully update vuex's state WPI

      this.$store.state.extremityCalculations.wpi.spine = this.fourthStage.wpi

      let spine = this.$store.state.extremityCalculations.body_parts.find((e) =>
        e.title.includes(this.data.spine_name)
      )

      //
      spine.calculation.wpi = this.fourthStage.wpi

      // Recalculate wpi

      return (this.$store.state.extremityCalculations.wpi.total =
        getCombinedValue(
          Object.values(this.$store.state.extremityCalculations.wpi)
        ))
    },
  },
}
</script>

<style lang="scss">
p,
span {
  line-height: 120%;
}
</style>

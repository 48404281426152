<template>
  <div>
    <div class="table-responsive">
      <table class="impairex-table table-bordered caption-top">
        <thead class="table-primary text-white">
          <tr>
            <th />
            <th />
            <th
              v-if="tableBody.right"
              scope="col"
              colspan="2"
              class="text-center"
            >
              Right
            </th>
            <th
              v-if="tableBody.left"
              scope="col"
              colspan="2"
              class="text-center"
            >
              Left
            </th>
          </tr>

          <tr>
            <th>Joints</th>
            <th></th>
            <th v-if="tableBody.right">Flexion</th>
            <th v-if="tableBody.right">Extension</th>

            <th v-if="tableBody.left">Flexion</th>
            <th v-if="tableBody.left">Extension</th>
          </tr>
        </thead>

        <tbody class="table-white table-bordered border-dark">
          <template v-for="(val, index) in tableBody.ipmp_row" :key="index">
            <tr>
              <th style="text-align: left" class="text-capitalize">
                {{ val.field_name }}
              </th>
              <td>Angleº</td>
              <td v-if="tableBody.right">
                {{ validDegree(val.right_flexion_angle) }}
              </td>
              <td v-if="tableBody.right">
                {{ validDegree(val.right_extension_angle) }}
              </td>

              <td v-if="tableBody.left">
                {{ validDegree(val.left_flexion_angle) }}
              </td>
              <td v-if="tableBody.left">
                {{ validDegree(val.left_extension_angle) }}
              </td>
            </tr>
          </template>
          <tr>
            <th colspan="2">&nbsp;</th>
            <th v-if="tableBody.right" colspan="2">&nbsp;</th>
            <th v-if="tableBody.left" colspan="2">&nbsp;</th>
          </tr>
          <!-- have hidden space for equal cell row height  -->
          <tr>
            <th>CMC</th>
            <td></td>
            <td></td>
            <td v-if="tableBody.right">Motion</td>

            <td v-if="tableBody.right && tableBody.left"></td>
            <td v-if="tableBody.left">Motion</td>
          </tr>

          <template v-for="val in tableBody.cmc_row" :key="val.field_name">
            <tr>
              <th></th>
              <td class="text-capitalize">{{ val.field_name }}</td>
              <td v-if="val.field_name === `radial abduction`">Angleº</td>
              <td v-else>Cm</td>
              <td v-if="tableBody.right">
                {{
                  val.field_name === `radial abduction`
                    ? validDegree(val.right_examination)
                    : val.right_examination != null
                    ? val.right_examination + 'cm'
                    : ''
                }}
              </td>

              <td v-if="tableBody.right && tableBody.left"></td>
              <td v-if="tableBody.left">
                {{
                  val.field_name === `radial abduction`
                    ? validDegree(val.left_examination)
                    : val.left_examination != null
                    ? val.left_examination + 'cm'
                    : ''
                }}
              </td>
            </tr>
          </template>

          <tr></tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import DegreeCheck from '@/mixins/DegreeCheckMixin'
export default {
  name: 'ThumbReportTable',
  mixins: [DegreeCheck],
  props: {
    thumbData: {
      type: [Array, Object],
      required: true,
    },
  },

  computed: {
    tableBody: function () {
      let refthumbData = JSON.parse(JSON.stringify(this.thumbData))

      let ipmp_row = []
      let ip = { field_name: 'IP' }
      let mp = { field_name: 'MP' }
      let add = { field_name: 'adduction' }
      let abd = { field_name: 'radial abduction' }
      let opp = { field_name: 'opposition' }
      let cmc_row = []

      //map left or right to single rows for table to render

      //preset it with an array of data
      refthumbData.direction.forEach((d) => {
        refthumbData[d].forEach((e) => {
          if (e.field_name.includes('ip')) {
            //impairment
            if (e.field_name.includes('extension')) {
              ip[`${d}_extension_angle`] = e.degree
            } else {
              ip[`${d}_flexion_angle`] = e.degree
            }
          } else if (e.field_name.includes('mp')) {
            if (e.field_name.includes('extension')) {
              mp[`${d}_extension_angle`] = e.degree
            } else {
              mp[`${d}_flexion_angle`] = e.degree
            }
          } else {
            if (e.field_name.includes('abduction')) {
              abd[`${d}_examination`] = e.degree
            } else if (e.field_name.includes('adduction')) {
              add[`${d}_examination`] = e.degree
            } else {
              opp[`${d}_examination`] = e.degree
            }
          }
        })
      })
      cmc_row.push(abd, add, opp)
      ipmp_row.push(ip, mp)

      refthumbData.ipmp_row = ipmp_row
      refthumbData.cmc_row = cmc_row

      return refthumbData
    },
  },

  methods: {
    validDegree(value) {
      return this.isDegree(value)
    },
  },
}
</script>

<style></style>

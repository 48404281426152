<template>
  <div>
    <div class="table-responsive">
      <table class="impairex-table table-bordered caption-top">
        <thead class="table-primary text-white">
          <tr>
            <th scope="col" />
            <th />

            <th
              v-if="tableBody.right"
              scope="col"
              colspan="3"
              class="text-center"
            >
              Right
            </th>
            <th
              v-if="tableBody.left"
              scope="col"
              colspan="3"
              class="text-center"
            >
              Left
            </th>
          </tr>

          <tr>
            <th>Joints</th>
            <th></th>
            <th v-if="tableBody.right">Flexion</th>
            <th v-if="tableBody.right">Extension</th>
            <th v-if="tableBody.right" class="pe-3">Sub Total Imp (%)</th>

            <th v-if="tableBody.left">Flexion</th>
            <th v-if="tableBody.left">Extension</th>
            <th v-if="tableBody.left" class="pe-3">Sub Total Imp (%)</th>
          </tr>
        </thead>

        <tbody class="table-white table-bordered border-dark">
          <template v-for="(val, index) in tableBody.ipmp_row" :key="index">
            <tr>
              <th style="text-align: left" class="text-capitalize">
                {{ val.field_name }}
              </th>
              <td>Angleº</td>
              <td v-if="tableBody.right">
                {{ validDegree(val.right_flexion_angle) }}
              </td>
              <td v-if="tableBody.right">
                {{ validDegree(val.right_extension_angle) }}
              </td>
              <td v-if="tableBody.right"></td>

              <td v-if="tableBody.left">
                {{ validDegree(val.left_flexion_angle) }}
              </td>
              <td v-if="tableBody.left">
                {{ validDegree(val.left_extension_angle) }}
              </td>
              <td v-if="tableBody.left"></td>
            </tr>
            <tr>
              <th></th>
              <td>Imp%</td>
              <td v-if="tableBody.right">
                {{ isPercent(val.right_flexion_impairment) }}
              </td>
              <td v-if="tableBody.right">
                {{ isPercent(val.right_extension_impairment) }}
              </td>

              <td v-if="tableBody.right">
                {{
                  isPercent(
                    val.right_flexion_impairment +
                      val.right_extension_impairment
                  )
                }}
              </td>
              <td v-if="tableBody.left">
                {{ isPercent(val.left_flexion_impairment) }}
              </td>
              <td v-if="tableBody.left">
                {{ isPercent(val.left_extension_impairment) }}
              </td>

              <td v-if="tableBody.left">
                {{
                  isPercent(
                    val.left_flexion_impairment + val.left_extension_impairment
                  )
                }}
              </td>
            </tr>
          </template>
          <tr>
            <th colspan="2">&nbsp;</th>
            <th v-if="tableBody.right" colspan="3">&nbsp;</th>
            <th v-if="tableBody.left" colspan="3">&nbsp;</th>
          </tr>
          <!-- have hidden space for equal cell row height  -->
          <tr>
            <th>CMC</th>
            <td></td>
            <td></td>
            <td v-if="tableBody.right">Motion</td>
            <td v-if="tableBody.right">Imp%</td>
            <td v-if="tableBody.right && tableBody.left"></td>
            <td v-if="tableBody.left">Motion</td>
            <td v-if="tableBody.left">Imp%</td>
          </tr>

          <template v-for="val in tableBody.cmc_row" :key="val.field_name">
            <tr>
              <th></th>
              <td class="text-capitalize">{{ val.field_name }}</td>
              <td v-if="val.field_name === `radial abduction`">Angleº</td>
              <td v-else>Cm</td>

              <td v-if="tableBody.right">
                {{
                  val.field_name === `radial abduction`
                    ? validDegree(val.right_examination)
                    : val.right_examination !== null
                    ? val.right_examination + 'cm'
                    : ''
                }}
              </td>
              <td v-if="tableBody.right">
                {{ isPercent(val.right_impairment) }}
              </td>
              <td v-if="tableBody.right && tableBody.left"></td>
              <td v-if="tableBody.left">
                {{
                  val.field_name === `radial abduction`
                    ? validDegree(val.left_examination)
                    : val.left_examination !== null
                    ? val.left_examination + 'cm'
                    : ''
                }}
              </td>
              <td v-if="tableBody.left">
                {{ isPercent(val.left_impairment) }}
              </td>
            </tr>
          </template>

          <tr></tr>
        </tbody>

        <tfoot>
          <tr>
            <th colspan="4">Total Thumb Impairment</th>

            <td v-if="tableBody.right">
              {{ isPercent(thumbData.right_calculation.di) }}
            </td>
            <td v-if="tableBody.right && tableBody.left" />
            <td v-if="tableBody.right && tableBody.left" />

            <td v-if="tableBody.left">
              {{ isPercent(thumbData.left_calculation.di) }}
            </td>
          </tr>

          <tr>
            <th colspan="4">Hand Impairment</th>

            <td v-if="tableBody.right">
              {{ isPercent(thumbData.right_calculation.hi) }}
            </td>
            <td v-if="tableBody.right && tableBody.left" />
            <td v-if="tableBody.right && tableBody.left" />

            <td v-if="tableBody.left">
              {{ isPercent(thumbData.left_calculation.hi) }}
            </td>
          </tr>
          <tr>
            <th colspan="4">Upper Extremity Impairment</th>

            <td v-if="tableBody.right">
              {{ isPercent(thumbData.right_calculation.uei) }}
            </td>
            <td v-if="tableBody.right && tableBody.left" />
            <td v-if="tableBody.right && tableBody.left" />

            <td v-if="tableBody.left">
              {{ isPercent(thumbData.left_calculation.uei) }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="py-4">
      <p v-for="side in tableBody.direction" :key="side">
        {{ thumbData.ama }} converts the above to

        <span style="font-weight: bold">
          {{ thumbData[`${side}_calculation`].wpi }}% Whole Person Impairment
        </span>
        for this
        <span style="font-weight: bold">
          {{ side }} {{ thumbData.title.toLowerCase() }}.
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { percentageCheck } from '@/utils/generics.js'
import DegreeCheck from '@/mixins/DegreeCheckMixin'

export default {
  name: 'ThumbImpairmentTable',
  mixins: [DegreeCheck],
  props: {
    thumbData: {
      type: [Array, Object],
      required: true,
    },
  },

  computed: {
    tableBody: function () {
      const { title, direction, left, right } = JSON.parse(
        JSON.stringify(this.thumbData)
      )

      let output = {
        title: title,
        left: left,
        right: right,
        direction: direction,
      }

      let ipmp_row = []
      let ip = { field_name: 'IP' }
      let mp = { field_name: 'MP' }
      let add = { field_name: 'adduction' }
      let abd = { field_name: 'radial abduction' }
      let opp = { field_name: 'opposition' }
      let cmc_row = []

      //map left or right to single rows for table to render

      //preset it with an array of data
      direction.forEach((d) => {
        this.thumbData?.[d]?.forEach((e) => {
          if (e.field_name.includes('ip')) {
            //impairment
            if (e.field_name.includes('extension')) {
              ip[`${d}_extension_angle`] = e.degree
              ip[`${d}_extension_impairment`] = e.impairment_value
            } else {
              ip[`${d}_flexion_angle`] = e.degree
              ip[`${d}_flexion_impairment`] = e.impairment_value
            }
          } else if (e.field_name.includes('mp')) {
            if (e.field_name.includes('extension')) {
              mp[`${d}_extension_angle`] = e.degree
              mp[`${d}_extension_impairment`] = e.impairment_value
            } else {
              mp[`${d}_flexion_angle`] = e.degree
              mp[`${d}_flexion_impairment`] = e.impairment_value
            }
          } else {
            if (e.field_name.includes('abduction')) {
              abd[`${d}_examination`] = e.degree
              abd[`${d}_impairment`] = e.impairment_value
            } else if (e.field_name.includes('adduction')) {
              add[`${d}_examination`] = e.degree
              add[`${d}_impairment`] = e.impairment_value
            } else {
              opp[`${d}_examination`] = e.degree
              opp[`${d}_impairment`] = e.impairment_value
            }
          }
        })
      })
      cmc_row.push(abd, add, opp)
      ipmp_row.push(ip, mp)

      output['ipmp_row'] = ipmp_row
      output['cmc_row'] = cmc_row

      return output
    },
  },

  methods: {
    validDegree(value) {
      return this.isDegree(value)
    },

    isPercent(value) {
      return percentageCheck(value)
    },
  },
}
</script>

<style></style>

<template>
  <p class="inline-block">
    The patient had clinical features of

    <!-- Radiculopathy is the impairment caused by malfunction of a spinal nerve root
or nerve roots. In general, in order to conclude that radiculopathy is present,
two or more of the following criteria must be present, one of which must be
major (major criteria in bold): -->

    {{ radiculopathyText }}

    <span v-if="isRadiculopathyPresent === 'verifiable'">
      and qualified for the radiculopathy requirements of the Paragraph 4.19,
      Page 42, of the Return To Work Scheme Impairment Assessment Guidelines
      where two or more of the requirement criteria must be present, one of
      which must be major ( major criteria are in bold ). According to this
      paragraph, the patient had verifiable radiculopathy”.
    </span>
    <span v-else>
      but did not meet the radiculopathy requirements of the Paragraph 4.19,
      Page 42, of the Return To Work Scheme Impairment Assessment Guidelines
      where two or more of the requirement criteria must be present, one of
      which must be major (major criteria in bold).
    </span>
  </p>
</template>

<script>
export default {
  name: 'RadiculopathySection',
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    abnormalFindings: {
      type: Object,
      default: () => {},
    },
    isRadiculopathyPresent: {
      type: [String, Boolean],
      default: false,
    },
    spine: {
      type: String,
      default: 'spine',
    },
  },

  data() {
    return {
      isReflexesNormal(obj) {
        let result
        Object.keys(obj).forEach((key) => {
          if (obj[key] == { right: 'normal', left: 'normal' }) {
            result = true
          } else result = false
        })

        return result
      },
    }
  },

  computed: {
    radiculopathyText() {
      let obj = this.data
      let finalAry = []
      let textResult

      //determine is radiculopathy major criteria is found
      if (this.abnormalFindings.consistent_abnormal_clinical_findings) {
        //validate reflexes
        if (obj.reflexes) {
          let reflexesAry = Object.keys(obj.reflexes)

          for (let i = 0; i < reflexesAry.length; i++) {
            let basedText = 'anatomically related to the injury'
            let key = reflexesAry[i]

            if (
              obj.reflexes[key].right == obj.reflexes[key].left &&
              obj.reflexes[key].left == 'absent'
            ) {
              if (!finalAry.includes('loss of tendon reflexes ' + basedText)) {
                finalAry.push('loss of tendon reflexes ' + basedText)
                break
              }
            } else if (obj.reflexes[key].right !== obj.reflexes[key].left) {
              if (
                !finalAry.includes('asymmetry of tendon reflexes ' + basedText)
              ) {
                finalAry.push('asymmetry of tendon reflexes ' + basedText)
                break
              }
            } else break
          }
        }

        //validate muscle weakness
        if (obj.muscle_weakness) {
          obj.muscle_weakness.power_level !== 'normal'
            ? finalAry.push(
                'muscle weakness that is anatomically localised to the appropriate spinal nerve root distribution'
              )
            : ''

          //validate abnormal pain
          obj.sensation.pain_level !== 'normal'
            ? finalAry.push(
                'reproducible impairment of sensation that is anatomically localised to the appropriate spinal nerve root distribution'
              )
            : ''
        }

        //validate nerve root test
        if (obj.nerve_root_tension_test) {
          let nerveRootAry = Object.keys(obj.nerve_root_tension_test)

          for (let i = 0; i < nerveRootAry.length; i++) {
            let basedText = 'positive nerve root tension test'
            let key = nerveRootAry[i]

            if (obj.nerve_root_tension_test[key] !== 'negative') {
              if (!finalAry.includes(basedText)) {
                finalAry.push(basedText)
                break
              }
            } else break
          }
        }

        //validate muscle wasting

        if (obj.muscle_wasting.atrophy) {
          if (this.spine === 'lumbar spine') {
            if (
              obj.muscle_wasting.thigh.right - obj.muscle_wasting.thigh.left >=
                2 ||
              obj.muscle_wasting.calf.right - obj.muscle_wasting.calf.left >= 1
            ) {
              finalAry.push('muscle wasting')
            }
          }
        }

        //validate roos test

        if (obj.roos_test) {
          finalAry.push(`${obj.roos_test.value} on roos stress test`)
        }

        //validate abnormal radiological

        let radiologyObj = this.abnormalFindings.abnormal_radiological_findings
        let radiologyAry = Object.keys(radiologyObj)

        radiologyAry.forEach(
          (e) => radiologyObj[e] == null && delete radiologyObj[e]
        )

        if (
          radiologyAry.length > 0 &&
          this.abnormalFindings.radiology_findings_consistent
        ) {
          finalAry.push(
            'findings on an imaging study consistent with the clinical signs of radiculopathy'
          )
        }
      }

      //join array to sentence
      if (finalAry.length > 0) {
        textResult = finalAry.reduce(
          (text, value, i, array) =>
            text + (i < array.length - 1 ? ', ' : ' and ') + value
        )
      }

      return textResult
    },
  },

  methods: {},
}
</script>

<style lang="scss" scoped>
div,
p {
  display: inline-block;
}
</style>

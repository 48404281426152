<template>
  <div>
    <p class="pb-2">
      The {{ bodyPartText }} had the following range of movement:
    </p>
    <!-- thumb only -->
    <div v-if="tableData.title === 'thumb'">
      <ThumbReportTable :thumb-data="tableData" />
    </div>
    <div v-else>
      <!-- finger end report mode -->
      <table class="impairex-table">
        <thead class="table-primary text-white align-middle">
          <tr class="text-center">
            <th colspan="2"></th>
            <th v-if="tableBody.right" scope="col" colspan="2">Right</th>
            <th v-if="tableBody.left" scope="col" colspan="2">Left</th>
          </tr>

          <tr>
            <th scope="col-2">Joints</th>
            <th></th>
            <th v-if="tableBody.right" scope="col">Flexion</th>
            <th v-if="tableBody.right" scope="col">Extension</th>
            <th v-if="tableBody.left" scope="col">Flexion</th>
            <th v-if="tableBody.left" scope="col">Extension</th>
          </tr>
        </thead>

        <tbody class="table-white table-bordered border-dark">
          <tr v-for="(part, index) in tableBody.fields_row" :key="index">
            <th scope="col-2" style="text-align: left" class="text-capitalize">
              {{ part.field_name }}
            </th>
            <td>Angleº</td>
            <td v-if="tableBody.right">
              {{ validDegree(part.right_flexion_angle) }}
            </td>
            <td v-if="tableBody.right">
              {{ validDegree(part.right_extension_angle) }}
            </td>

            <td v-if="tableBody.left">
              {{ validDegree(part.left_flexion_angle) }}
            </td>
            <td v-if="tableBody.left">
              {{ validDegree(part.left_extension_angle) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import DegreeCheck from '@/mixins/DegreeCheckMixin'
import ThumbReportTable from '@/components/Report/ThumbReportTable'
export default {
  name: 'FingersReportTable',
  components: { ThumbReportTable },
  mixins: [DegreeCheck],
  props: {
    tableData: {
      type: [Array, Object],
      required: true,
    },
  },

  computed: {
    tableBody: function () {
      let refTableData = JSON.parse(JSON.stringify(this.tableData))

      let combinedFieldsAry = []
      let dip = { field_name: 'DIP' }
      let pip = { field_name: 'PIP' }
      let mp = { field_name: 'MP' }

      //map left or right to single rows for table to render

      //preset it with an array of data

      //use examination header to determine if both side needs to be displayed
      let directionToDisplay = refTableData.examinationHeader
      directionToDisplay.splice(0, 1)

      directionToDisplay.forEach((d) => {
        refTableData?.[d.toLowerCase()]?.forEach((e) => {
          if (e.field_name.includes('dip')) {
            //impairment
            if (e.field_name.includes('extension')) {
              dip[`${d.toLowerCase()}_extension_angle`] = e.degree
            } else {
              dip[`${d.toLowerCase()}_flexion_angle`] = e.degree
            }
          } else if (e.field_name.includes('pip')) {
            if (e.field_name.includes('extension')) {
              pip[`${d.toLowerCase()}_extension_angle`] = e.degree
            } else {
              pip[`${d.toLowerCase()}_flexion_angle`] = e.degree
            }
          } else {
            if (e.field_name.includes('extension')) {
              mp[`${d.toLowerCase()}_extension_angle`] = e.degree
            } else {
              mp[`${d.toLowerCase()}_flexion_angle`] = e.degree
            }
          }
        })
      })

      //finger field now has a fixed sequence

      combinedFieldsAry.push(mp, pip, dip)

      refTableData.fields_row = combinedFieldsAry

      return refTableData
    },
    bodyPartText() {
      if (this.tableData.examinationHeader.length > 2) {
        return this.tableData.title + 's'
      } else return this.tableData.title
    },
  },

  methods: {
    validDegree(value) {
      return this.isDegree(value)
    },
  },
}
</script>

<style></style>

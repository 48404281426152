<template>
  <p class="d-inline">
    The patient had confirmed {{ spine }} vertebral fractures on the
    radiological study:
    <span
      v-for="(segment, idx) in data.spinal_fractures.segments"
      :key="segment"
    >
      from the Vertebral Height Loss of {{ segment.value }}% of
      <span class="text-uppercase">{{ segment.name }}</span>
      <span v-if="idx === data.spinal_fractures.segments.length">.</span>
      <span v-else>,</span>
    </span>
    The total loss was {{ sum }}%.
    <span v-if="sum >= 25 && sum < 51">
      This qualified for the “Total loss of 25% to 50%= DRE III” in the
      Paragraph 4.22, page 43, of the Return To Work Scheme Impairment
      Assessment Guidelines.
    </span>
  </p>
</template>

<script>
// calculation validation of right and left thigh and calf is done on parent component
export default {
  name: 'VertebralHeightLossParagraph',
  props: {
    spine: {
      type: String,
      default: 'spine',
    },
    data: {
      type: Object,
      default: () => {},
    },
    sum: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss"></style>

<template>
  <div>
    <!-- thumb only -->
    <div v-if="tableData.title === 'thumb'">
      <ThumbImpairmentTable :thumb-data="tableData" />
    </div>

    <!-- non thumb finger -->
    <div v-else>
      <div class="table-responsive">
        <table class="impairex-table table-bordered caption-top">
          <thead class="table-primary text-white">
            <tr>
              <th scope="col" />
              <th />

              <th
                v-if="tableBody.right"
                scope="col"
                colspan="3"
                class="text-center"
              >
                Right
              </th>
              <th
                v-if="tableBody.left"
                scope="col"
                colspan="3"
                class="text-center"
              >
                Left
              </th>
            </tr>

            <tr>
              <th>Joints</th>
              <th></th>
              <th v-if="tableBody.right">Flexion</th>
              <th v-if="tableBody.right">Extension</th>
              <th v-if="tableBody.right" class="pe-3">Sub Total Imp (%)</th>

              <th v-if="tableBody.left">Flexion</th>
              <th v-if="tableBody.left">Extension</th>
              <th v-if="tableBody.left" class="pe-3">Sub Total Imp (%)</th>
            </tr>
          </thead>

          <tbody class="table-white table-bordered border-dark">
            <template v-for="(val, index) in tableBody.fields_row" :key="index">
              <tr>
                <th style="text-align: left" class="text-capitalize">
                  {{ val.field_name }}
                </th>
                <td>Angleº</td>
                <td v-if="tableBody.right">
                  {{ validDegree(val.right_flexion_angle) }}
                </td>
                <td v-if="tableBody.right">
                  {{ validDegree(val.right_extension_angle) }}
                </td>
                <td v-if="tableBody.right"></td>

                <td v-if="tableBody.left">
                  {{ validDegree(val.left_flexion_angle) }}
                </td>
                <td v-if="tableBody.left">
                  {{ validDegree(val.left_extension_angle) }}
                </td>
                <td v-if="tableBody.left"></td>
              </tr>
              <tr>
                <th></th>
                <td>Imp%</td>
                <td v-if="tableBody.right">
                  {{ isPercent(val.right_flexion_impairment) }}
                </td>
                <td v-if="tableBody.right">
                  {{ isPercent(val.right_extension_impairment) }}
                </td>

                <td v-if="tableBody.right">
                  {{
                    isPercent(
                      val.right_flexion_impairment +
                        val.right_extension_impairment
                    )
                  }}
                </td>
                <td v-if="tableBody.left">
                  {{ isPercent(val.left_flexion_impairment) }}
                </td>
                <td v-if="tableBody.left">
                  {{ isPercent(val.left_extension_impairment) }}
                </td>

                <td v-if="tableBody.left">
                  {{
                    isPercent(
                      val.left_flexion_impairment +
                        val.left_extension_impairment
                    )
                  }}
                </td>
              </tr>
              <!-- have hidden space for equal cell row height  -->
              <tr>
                <th>&nbsp;</th>
                <th v-if="tableBody.right" colspan="3">&nbsp;</th>
                <th v-if="tableBody.left" colspan="3">&nbsp;</th>
              </tr>
            </template>
          </tbody>

          <tfoot>
            <tr>
              <th colspan="4">Combine Digit Impairment % MP, PIP, DIP</th>

              <td v-if="tableBody.right">
                {{ isPercent(tableData.right_calculation.di) }}
              </td>
              <td v-if="tableBody.right && tableBody.left" />
              <td v-if="tableBody.right && tableBody.left" />

              <td v-if="tableBody.left">
                {{ isPercent(tableData.left_calculation.di) }}
              </td>
            </tr>
            <tr>
              <th colspan="4">Abnormal Motion Impairment</th>

              <td v-if="tableBody.right">
                {{ isPercent(tableData.right_calculation.di) }}
              </td>
              <td v-if="tableBody.right && tableBody.left" />
              <td v-if="tableBody.right && tableBody.left" />

              <td v-if="tableBody.left">
                {{ isPercent(tableData.left_calculation.di) }}
              </td>
            </tr>
            <tr>
              <th colspan="4">Hand Impairment</th>

              <td v-if="tableBody.right">
                {{ isPercent(tableData.right_calculation.hi) }}
              </td>
              <td v-if="tableBody.right && tableBody.left" />
              <td v-if="tableBody.right && tableBody.left" />

              <td v-if="tableBody.left">
                {{ isPercent(tableData.left_calculation.hi) }}
              </td>
            </tr>
            <tr>
              <th colspan="4">Upper Extremity Impairment</th>

              <td v-if="tableBody.right">
                {{ isPercent(tableData.right_calculation.uei) }}
              </td>
              <td v-if="tableBody.right && tableBody.left" />
              <td v-if="tableBody.right && tableBody.left" />

              <td v-if="tableBody.left">
                {{ isPercent(tableData.left_calculation.uei) }}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div class="py-4">
        <p v-for="side in tableBody.direction" :key="side">
          {{ tableData.ama }} converts the above to

          <span style="font-weight: bold">
            {{ tableData[`${side}_calculation`].wpi }}% Whole Person Impairment
          </span>
          for this
          <span style="font-weight: bold">
            {{ side }} {{ tableBody.title.toLowerCase() }}.
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import DegreeCheck from '@/mixins/DegreeCheckMixin'
import { percentageCheck } from '@/utils/generics.js'
import ThumbImpairmentTable from '@/components/Report/ThumbImpairmentTable'
export default {
  name: 'FingersImpairmentTable',
  components: { ThumbImpairmentTable },
  mixins: [DegreeCheck],
  props: {
    tableData: {
      type: [Array, Object],
      required: true,
    },
  },

  computed: {
    tableBody: function () {
      // deconstruct to use only properties this prop needs

      const { title, direction, left, right } = JSON.parse(
        JSON.stringify(this.tableData)
      )

      let output = {
        title: title,
        left: left,
        right: right,
        direction: direction,
      }

      let combinedFieldsAry = []
      let dip = { field_name: 'DIP' }
      let pip = { field_name: 'PIP' }
      let mp = { field_name: 'MP' }

      // map left or right to single rows for table to render

      // selected direction

      direction.forEach((d) => {
        this.tableData?.[d]?.forEach((e) => {
          if (e.field_name.includes('dip')) {
            //impairment
            if (e.field_name.includes('extension')) {
              dip[`${d.toLowerCase()}_extension_impairment`] =
                e.impairment_value

              dip[`${d.toLowerCase()}_extension_angle`] = e.degree
            } else {
              dip[`${d.toLowerCase()}_flexion_impairment`] = e.impairment_value
              dip[`${d.toLowerCase()}_flexion_angle`] = e.degree
            }
          } else if (e.field_name.includes('pip')) {
            if (e.field_name.includes('extension')) {
              pip[`${d.toLowerCase()}_extension_impairment`] =
                e.impairment_value

              pip[`${d.toLowerCase()}_extension_angle`] = e.degree
            } else {
              pip[`${d.toLowerCase()}_flexion_impairment`] = e.impairment_value
              pip[`${d.toLowerCase()}_flexion_angle`] = e.degree
            }
          } else {
            if (e.field_name.includes('extension')) {
              mp[`${d.toLowerCase()}_extension_impairment`] = e.impairment_value

              mp[`${d.toLowerCase()}_extension_angle`] = e.degree
            } else {
              mp[`${d.toLowerCase()}_flexion_impairment`] = e.impairment_value
              mp[`${d.toLowerCase()}_flexion_angle`] = e.degree
            }
          }
        })
      })

      // finger field now has a fixed sequence

      combinedFieldsAry.push(mp, pip, dip)
      output['fields_row'] = combinedFieldsAry

      return output
    },
  },

  methods: {
    validDegree(value) {
      return this.isDegree(value)
    },

    isPercent(value) {
      return percentageCheck(value)
    },
  },
}
</script>

<style></style>

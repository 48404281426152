<template>
  <div>
    <p>The {{ tableData.spineName }} had the following examination:</p>
    <p>
      <span class="text-capitalize">{{ tableData.patientGender }}</span>
      {{ muscleToneResult }}. The {{ tableData.spineName }} range of movement
      was measured with an Acumar dual digital inclinometer as described by the
      AMA 5 Guides as follows:
    </p>
    <table class="impairex-table mt-2 mb-5">
      <thead class="table-primary text-white">
        <tr>
          <th
            v-for="(item, index) in tableData.tableHead"
            :key="index"
            scope="col"
            class="text-capitalize"
          >
            {{ item }}
          </th>
        </tr>
      </thead>

      <tbody class="table-white table-bordered border-dark">
        <tr
          v-for="(part, index) in tableData.tableRows"
          :key="index"
          class="table-light"
        >
          <th scope="row" class="text-capitalize">
            {{ part.field_name }}
          </th>

          <td class="pt-4">
            {{ validDegree(part.examination_value) }}
          </td>
        </tr>
      </tbody>
    </table>

    <div class="my-4">
      <p>
        Neurologically
        {{ limbOutput ? limbOutput : 'para thoracic muscle' }} had
        {{ painSensationOutput }}. The reflexes were {{ reflexesOutput }}.
        <span v-if="limbOutput">
          The {{ limbOutput }} power was {{ powerTestOutput }}.
        </span>
        <span v-if="straightLegTestOutput">
          The straight leg raise test was {{ straightLegTestOutput }} and the
          slump test was {{ slumpTestOutput }}.
        </span>
        <span v-if="roosTestOutput">
          The roos stress test was {{ roosTestOutput }} .
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { formatLongConjunction } from '@/utils/generics.js'

import DegreeCheck from '@/mixins/DegreeCheckMixin'

export default {
  name: 'SpineExaminationTable',
  mixins: [DegreeCheck],
  props: {
    tableData: {
      type: [Array, Object],
      required: true,
    },
  },

  computed: {
    limbOutput() {
      if (this.tableData.spineName === 'thoracic spine') {
        return ''
      } else if (this.tableData.spineName === 'cervical spine') {
        return 'upper limb'
      } else return 'lower limb'
    },

    painSensationOutput() {
      //normal pain sensation/reduced pain sensation/increased pain sensation/ {free text} on pin prick testing.

      if (Object.keys(this.tableData.spineData).length > 0) {
        return (
          this.tableData.spineData.sensation.pain_level +
          ' pain sensation on pin prick testing' +
          (this.tableData.spineData.sensation.abnormal_pain_additional_info
            ? ', ' +
              this.tableData.spineData.sensation.abnormal_pain_additional_info
            : '')
        )
      } else return ''
    },

    muscleToneResult() {
      let textAry = []

      this.tableData.spineData.muscles.forEach((x) => {
        let text
        x.spasm === 'none' && x.tenderness === 'none'
          ? (text = `did not have tenderness and spasm of the ${x.name} bilaterally`)
          : (text = `had tenderness and spasm of the ${x.name} bilaterally`)
        textAry.push(text)
      })

      return textAry.join(' and ')
    },

    reflexesOutput() {
      let textAry = []

      if (this.tableData.spineData.reflexes) {
        Object.keys(this.tableData.spineData.reflexes).forEach((key) => {
          if (
            this.tableData.spineData.reflexes[key].right !== 'normal' &&
            this.tableData.spineData.reflexes[key].left !== 'normal'
          ) {
            textAry.push(
              `${this.tableData.spineData.reflexes[key].right} reflexes on right ${key}`,
              `${this.tableData.spineData.reflexes[key].left} reflexes on left ${key}`
            )
          } else textAry.push(`normal reflexes on both ${key}s`)
        })

        return formatLongConjunction(textAry)
      } else return ''
    },

    roosTestOutput() {
      if (this.tableData.spineData.roos_test) {
        return (
          this.tableData.spineData.roos_test.value +
          (this.tableData.spineData.roos_test.comments
            ? ', ' + this.tableData.spineData.roos_test.comments
            : '')
        )
      } else return ''
    },

    powerTestOutput() {
      return (
        this.tableData.spineData.muscle_weakness.power_level +
        (this.tableData.spineData.muscle_weakness.power_level_comments
          ? ', ' + this.tableData.spineData.muscle_weakness.power_level_comments
          : '')
      )
    },

    straightLegTestOutput() {
      if (this.tableData.spineData.nerve_root_tension_test) {
        return this.tableData.spineData.nerve_root_tension_test
          .straight_leg_test_failed_leg === 'both' ||
          this.tableData.spineData.nerve_root_tension_test
            .straight_leg_test_failed_leg === null
          ? `${this.tableData.spineData.nerve_root_tension_test.leg_straight_test} on both legs`
          : `${this.tableData.spineData.nerve_root_tension_test.leg_straight_test} on ${this.tableData.spineData.nerve_root_tension_test.straight_leg_test_failed_leg} leg`
      } else return ''
    },

    slumpTestOutput() {
      if (this.tableData.spineData.nerve_root_tension_test) {
        return this.tableData.spineData.nerve_root_tension_test.slump_test ===
          'negative'
          ? 'negative'
          : 'postive'
      } else return ''
    },
  },

  methods: {
    validDegree(value) {
      return this.isDegree(value)
    },
  },
}
</script>

<style></style>

export default {
  name: 'DegreeCheckMixin',
  methods: {
    isDegree(value) {
      if (!isNaN(value) && value != null) {
        if (value === false) {
          return 'No'
        } else if (value === true) {
          return 'Yes'
        } else return value + '°'
      } else return value
    },
  },
}

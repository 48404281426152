<template>
  <div>
    <div class="table-responsive">
      <table class="impairex-table">
        <thead class="table-primary text-white">
          <tr>
            <th
              v-for="(item, index) in tableBody.impairmentHeader"
              :key="index"
              scope="col"
              class="text-capitalize"
            >
              {{ item }}
            </th>
          </tr>
        </thead>

        <tbody class="table-white table-bordered border-dark">
          <template v-for="(val, index) in tableBody.fields_row" :key="index">
            <tr>
              <th style="text-align: left" class="text-capitalize">
                {{ val.field_name }}
              </th>
              <td v-if="showRight">
                {{ validDegree(val.right.examination_value) }}
              </td>
              <td v-if="showRight">
                {{ isPercent(val.right.impairment_value) }}
              </td>
              <td v-if="showLeft">
                {{ validDegree(val.left.examination_value) }}
              </td>
              <td v-if="showLeft">
                {{ isPercent(val.left.impairment_value) }}
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr>
            <th class="text-capitalize">Total</th>
            <td v-if="showRight" />

            <td v-if="showRight">
              {{ isPercent(totalUEI.right) }}
            </td>
            <td v-if="showLeft" />

            <td v-if="showLeft">
              {{ isPercent(totalUEI.left) }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <!-- shoulder related paragraphs -->
    <div class="my-3">
      <div v-if="excision.right && !excision.left">
        <p>
          The patient had a lateral clavicle excision on the Right Shoulder.
          According to the Paragraph 2.16 of the Return To Work Scheme
          Impairment Assessment Guidelines, there is a combinable 5% upper
          extremity impairment for (a) the total shoulder acromioclavicular
          joint (distal clavicle) resection arthroplasty and (b) the patient has
          a surgical scar on the clinical examination consistent with this
          anatomical loss. This is combined to the above shoulder impairment and
          equals to
          {{ tableBody.right_calculation.uei }}% upper extremity impairment.
        </p>
      </div>
      <div v-if="excision.left && !excision.right">
        <p>
          The patient had a lateral clavicle excision on the Left Shoulder.
          According to the Paragraph 2.16 of the Return To Work Scheme
          Impairment Assessment Guidelines, there is a combinable 5% upper
          extremity impairment for (a) the total shoulder acromioclavicular
          joint (distal clavicle) resection arthroplasty and (b) the patient has
          a surgical scar on the clinical examination consistent with this
          anatomical loss. This is combined to the above shoulder impairment and
          equals to
          {{ tableBody.left_calculation.uei }}% upper extremity impairment.
        </p>
      </div>

      <div v-if="excision.right && excision.left">
        <p>
          The patient had a lateral clavicle excision on the Right Shoulder.
          According to the Paragraph 2.16 of the Return To Work Scheme
          Impairment Assessment Guidelines, there is a combinable 5% upper
          extremity impairment for (a) the total shoulder acromioclavicular
          joint (distal clavicle) resection arthroplasty and (b) the patient has
          a surgical scar on the clinical examination consistent with this
          anatomical loss. This is combined to the above shoulder impairment and
          equals to
          {{ tableBody.right_calculation.wpi }}% upper extremity impairment.
        </p>
        <p>
          The patient had a lateral clavicle excision on the Left Shoulder .
          According to the Paragraph 2.16 of the Return To Work Scheme
          Impairment Assessment Guidelines, there is a combinable 5% upper
          extremity impairment for (a) the total shoulder acromioclavicular
          joint (distal clavicle) resection arthroplasty and (b) the patient has
          a surgical scar on the clinical examination consistent with this
          anatomical loss. This is combined to the above shoulder impairment and
          equals to
          {{ tableBody.left_calculation.uei }}% upper extremity impairment.
        </p>
      </div>
    </div>

    <div class="py-4">
      <p v-for="side in tableBody.direction" :key="side">
        {{ tableData.ama }} converts the above to

        <span style="font-weight: bold">
          {{ tableData[`${side}_calculation`].wpi }}% Whole Person Impairment
        </span>
        for this
        <span style="font-weight: bold">
          {{ side }} {{ tableBody.title.toLowerCase() }}.
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import DegreeCheck from '@/mixins/DegreeCheckMixin'
import { percentageCheck } from '@/utils/generics.js'

//table field ordering
import FieldOrders from '@/assets/files/ImpairmentTableFieldsOrder.json'
export default {
  name: 'DefaultImpairmentTable',
  mixins: [DegreeCheck],
  props: {
    tableData: {
      type: [Array, Object],
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    tableBody: function () {
      let refTableData = JSON.parse(JSON.stringify(this.tableData))

      // Do not display these
      let doNotDisplay = ['ac joint excision']

      let direction = ['left', 'right']

      let fieldsRowAry = []

      // Combine left and right into single obj inside an array
      // Each obj will have left/right depending on assessed direction

      //

      direction.forEach((d) => {
        if (refTableData.examinationHeader.includes(d)) {
          // Both sides of the body part share the same number of assessable parts
          refTableData[d].forEach((e) => {
            // If opposite side is present,
            // Add opposite side's impairment details onto array

            let found = fieldsRowAry.find((x) => x.field_name === e.field_name)

            // Map opposite side's data onto existing element inside the array
            if (found) {
              let oppositeSide = {
                impairment_value: e.impairment_value,
                examination_value: e.degree ?? e.value,
              }
              found[d] = oppositeSide
            } else {
              fieldsRowAry.push({
                field_name: e.field_name,
                [`${d}`]: {
                  impairment_value: e.impairment_value,
                  examination_value: e.degree ?? e.value,
                },
              })
            }
          })
        }
      })

      // Filter out fields that shouldn't be displayed

      fieldsRowAry = fieldsRowAry.filter(
        (field) => !doNotDisplay.includes(field.field_name)
      )

      //now we have to sort the sequence of the table fields

      //get specific body part sequence
      const sortByObject = FieldOrders[this.tableData.title].reduce(
        (obj, item, index) => {
          return {
            ...obj,
            [item]: index,
          }
        },
        {}
      )

      refTableData.fields_row = fieldsRowAry.sort(
        (a, b) => sortByObject[a.field_name] - sortByObject[b.field_name]
      )

      return refTableData
    },

    excision: function () {
      let result = { right: false, left: false }

      this.tableData.direction.forEach((d) => {
        let ac = this.tableData[`${d}`].find(
          (e) => e.field_name === 'ac joint excision'
        )

        if (ac) {
          ac.impairment_value ? (result[d] = true) : null
        }
      })

      return result
    },

    totalUEI() {
      // Do not display shoulder excision's extra impairment value onto the table UEI
      let finalUEI = {
        left: 0,
        right: 0,
      }

      let direction = ['right', 'left']
      direction.forEach((d) => {
        if (this.tableBody?.[d]) {
          finalUEI[d] = this.tableBody[`${d}_calculation`].uei
          if (this.excision[d]) {
            finalUEI[d] -= 5
          }
        }
      })

      return finalUEI
    },

    showLeft() {
      if (this.tableBody.left_calculation) {
        return true
      } else return false
    },

    showRight() {
      if (this.tableBody.right_calculation) {
        return true
      } else return false
    },
  },

  mounted() {},

  methods: {
    validDegree(value) {
      return this.isDegree(value)
    },

    isPercent(value) {
      return percentageCheck(value)
    },
  },
}
</script>

<style></style>

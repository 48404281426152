<template>
  <div>
    <!-- standard end report mode -->
    <p class="pb-2">
      The {{ bodyPartText }} had the following range of movement:
    </p>
    <table class="impairex-table">
      <thead class="table-primary text-white">
        <tr>
          <th
            v-for="(item, index) in tableBody.examinationHeader"
            :key="index"
            scope="col"
            class="text-capitalize"
          >
            {{ item }}
          </th>
        </tr>
      </thead>

      <tbody class="table-white table-bordered border-dark">
        <tr v-for="(row, index) in tableBody.fields_row" :key="index">
          <th style="text-align: left" class="text-capitalize">
            {{ row?.field_name }}
          </th>
          <td v-if="row?.right">
            {{ validDegree(row.right.examination_value) }}
          </td>
          <td v-if="row?.left">
            {{ validDegree(row.left.examination_value) }}
          </td>
        </tr>
      </tbody>
    </table>
    <!-- shoulder related paragraphs -->
    <div v-if="excisionDirection" class="my-5">
      <p>
        The {{ excisionDirection }} shoulder had a scar that was consistent with
        the AC joint resection previously.
      </p>
    </div>
  </div>
</template>

<script>
//table field ordering
import FieldOrders from '@/assets/files/ImpairmentTableFieldsOrder.json'

import DegreeCheck from '@/mixins/DegreeCheckMixin'

export default {
  name: 'DefaultReportTable',
  mixins: [DegreeCheck],
  props: {
    tableData: {
      type: [Object],
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    tableBody: function () {
      let refTableData = JSON.parse(JSON.stringify(this.tableData))

      // Do not display these
      let doNotDisplay = ['ac joint excision']

      let direction = ['left', 'right']

      let fieldsRowAry = []

      // Combine left and right into single obj inside an array
      // Each obj will have left/right depending on assessed direction

      //

      direction.forEach((d) => {
        if (refTableData.examinationHeader.includes(d)) {
          // Both sides of the body part share the same number of assessable parts
          refTableData[d].forEach((e) => {
            // If opposite side is present,
            // Add opposite side's impairment details onto array

            let found = fieldsRowAry.find((x) => x.field_name === e.field_name)

            // Map opposite side's data onto existing element inside the array
            if (found) {
              let oppositeSide = {
                impairment_value: e.impairment_value,
                examination_value: e.degree ?? e.value,
              }
              found[d] = oppositeSide
            } else {
              fieldsRowAry.push({
                field_name: e.field_name,
                [`${d}`]: {
                  impairment_value: e.impairment_value,
                  examination_value: e.degree ?? e.value,
                },
              })
            }
          })
        }
      })

      // Filter out fields that shouldn't be displayed

      fieldsRowAry = fieldsRowAry.filter(
        (field) => !doNotDisplay.includes(field.field_name)
      )

      //now we have to sort the sequence of the table fields

      //get specific body part sequence
      const sortByObject = FieldOrders[this.tableData.title].reduce(
        (obj, item, index) => {
          return {
            ...obj,
            [item]: index,
          }
        },
        {}
      )

      refTableData.fields_row = fieldsRowAry.sort(
        (a, b) => sortByObject[a.field_name] - sortByObject[b.field_name]
      )

      return refTableData
    },
    excisionDirection: function () {
      if (this.tableData.direction.length) {
        let textResult = []
        this.tableData.direction.forEach((d) => {
          let ac = this.tableData[d].findIndex(
            (x) => x.field_name === 'ac joint excision'
          )

          //detect by yes and no instead of boolean
          if (ac != -1) {
            if (this.tableData[d][ac].value === 'yes') {
              return textResult.push(d)
            }
          }
        })

        return textResult.join(' and ')
      } else return null
    },

    bodyPartText() {
      if (this.tableData.examinationHeader.length > 2) {
        return this.tableData.title + 's'
      } else return this.tableData.title
    },
  },

  methods: {
    validDegree(value) {
      return this.isDegree(value)
    },
  },
}
</script>

<style></style>
